import React from 'react'


function LandingPage() {
    return (
        <div>
            <div className="ui">

            </div>
        </div>
    )
}

export default LandingPage
