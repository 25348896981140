import './App.css';
import Navbar from './NavBar/NavBar';
import SideNavPage from './NavBar/SideNavPage';
import LandingPage from './LandingPage/LandingPage'
import Game from './Game/Game'
function App() {
  return (
  <>

  
    <Navbar/> 
    <SideNavPage/>
    <Game/>
  
   
   
  </>

  
   

  );
}

export default App;
